import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivacyPolicy from './screens/PrivacyPolicy';
import WebsiteFooter from './components/WebsiteFooter';
import Support from './screens/Support';
import HomeScreen from './screens/HomeScreen';
import WebsiteHeader from './components/WebsiteHeader';

function App() {
  return (
    <div className="App">
      <WebsiteHeader />
      <div className="router">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
      </Routes>
      </BrowserRouter>
      </div>
      <WebsiteFooter />
    </div>
  );
}

export default App;
