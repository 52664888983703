import React from "react";
import logo from "../images/CRWDIcon.png"
import homeshot from "../images/HomeScreenshot.png"
import bookingmanager from "../images/BookingManager.png"
import chatshot from "../images/ChatScreenshot.png"
import bookshot from "../images/MakeBookingScreenshot.png"
import profileshot from "../images/ProfilePlacesScreenshot.png"


export default function HomeScreen() {
    return <div className="homeScreen">
        <img src={logo} alt="logo" className="homeLogo"/>
        <h4>CRWD</h4>
        <div className="homeHeader">
        <h1>Discover<br/>
            Book<br/>
            Enjoy
            </h1>
        </div>
        <a className="downloadButtonMain" href="https://apps.apple.com/us/app/crwd/id6444104160">Join the CRWD!</a>
        <div className="screenshots">
        <img src={chatshot} alt="chat"/>
        <img src={homeshot} alt="home"/>
        <img src={bookingmanager} alt="booking"/>
        </div>
        <div className="cards-Container">
        <div className="cards">
            <div className="card">
                <div className="card-copy">
                    <p>
                        Never miss your favorite 
                        <strong> artists </strong>
                         again. Find all the top events in 
                        <strong> one place. </strong>
                    </p>
                </div>
                <img src={homeshot} alt="home" />
            </div>
            <div className="card">
                <div className="card-copy">
                    <p>
                        Easily manage all your 
                        <strong> bookings. </strong>
                         And add your
                         <strong> friends </strong>
                         so everyone knows the
                         <strong> plan.</strong>
                    </p>
                </div>
                <img src={bookingmanager} alt="booking" />
            </div>
            <div className="card">
                <div className="card-copy">
                    <p>
                        Chat with our dedicated 
                        <strong> concierge </strong>
                         for 
                         <strong> anything </strong>
                         you want.
                         Our network of 
                         <strong> partners </strong>
                         will help get it.
                    </p>
                </div>
                <img src={chatshot} alt="chat" />
            </div>
            <div className="card">
                <div className="card-copy">
                    <p>
                        Make bookings at
                        <strong> Dubai's </strong>
                         top locations.
                         All in a 
                         <strong> simple </strong>
                         two step process.
                    </p>
                </div>
                <img src={bookshot} alt="chat" />
            </div>
            <div className="card">
                <div className="card-copy">
                    <p>
                        Follow your 
                        <strong> friends. </strong>
                         See what they are
                         <strong> interested </strong>
                         in and
                         <strong> recommend </strong>
                         so you always have ideas for your next
                         <strong> plan.</strong>
                    </p>
                </div>
                <img src={profileshot} alt="chat" />
            </div>
            </div>
        </div> {/*
        <h1>All the top venues in one place.</h1>
        <div className="venue-showcase">
        <ul className="venueRowOne">
        <li><VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/></li>
        <li><VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/></li>
        <li><VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/></li>
        <li><VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/></li>
        </ul>
        <ul className="venueRowTwo">
        <li><VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/></li>
        <li><VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/></li>
        <li><VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/></li>
        </ul>
        </div>
*/}
        </div>
}