import React from "react";

export default function WebsiteFooter() {
    return <div className="footer">
        <ul className="footerLinks">
            <li><a href="mailto: help@thecrwd.com">Contact Us</a></li>
            <li><a href= "/support">Support</a></li>
            <li><a href= "/privacypolicy">Privacy Policy</a></li>
        </ul>
        <p>Copyright © 2022 Plug Investments Pty Ltd. All rights reserved.</p>
    </div>
}